import axios from 'axios';
import _ from 'lodash';
import { AES_CBC_DECRYPT } from './crypto.js';
import { isClient } from './envHelper.js';
import { isOversea } from './urlHelper';
import { broadcastChannel } from './broadcastChannel'

const getData = async (cnURL, globalUrl) => {
  let result = '';
  const urls = isOversea() ? [globalUrl, cnURL] : [cnURL, globalUrl];
  if (_.isNil(urls[0])) return result;
  result = await axios
    .get(`${urls[0]}?v=${new Date().getTime()}`)
    .then(res => res.data)
    .catch(err => {
      console.log(err);
    });
  if (_.isEmpty(result)) {
    result = await axios
      .get(`${urls[1]}?v=${new Date().getTime()}`)
      .then(res => res.data)
      .catch(err => {
        console.log(err);
      });
  }
  return result;
};

/**
 * 获取WS用的国内域名
 * @returns {Promise<string[] | null>}
 */
export const getWSCNDomains = async () => {
  const result = await getData(process.env.WS_LIST_TXT_CN, process.env.WS_LIST_TXT_GLOBAL)
    .then(x => {
      const domains = AES_CBC_DECRYPT(x);
      console.log('getWSCNDomains domains', domains)
      return domains.split(',').map(y => y.trim())
    })
    .catch(err => {
      console.log(err);
      return null;
    });
    if(isClient && window.$nuxt) window.$nuxt.$store.commit('SET_WS_DOMAINS', result);
  return result;
};

/**
 * 获取WS用的国内域名
 * @returns {Promise<string | null>}
 */
export const getWSCNDomain = async () => {
  let domain = isClient ? localStorage.getItem('wss:url') : '';
  console.log('domain', domain)
  const domains = await getWSCNDomains();
  console.log('domains', domains)
  if (domains) {
    let num = domains.length;
    domain = domains[Math.floor(Math.random() * num)];
    if (isClient) {
      broadcastChannel('setItem','wss:url', domain);
    }
  }
  return domain;
};

/**
 * 获取API用的国内域名
 * @returns {Promise<string[] | null>}
 */
export const getAPICNDomains = async () => {
  const result = await getData(process.env.API_LIST_TXT_CN, process.env.API_LIST_TXT_GLOBAL)
    .then((x) => {
      const domains = AES_CBC_DECRYPT(x);
      return domains.split(',').map((y) => y.trim())
    })
    .catch(err => {
      console.log(err);
      return null;
    });
  if(isClient && window.$nuxt) window.$nuxt.$store.commit('SET_API_DOMAINS', result);
  return result;
};

/**
 * 获取API用的国内域名
 * @returns {Promise<string | null>}
 */
export const getAPICNDomain = async () => {
  let domain = ''
  const domains = await getAPICNDomains();
  if (domains) {
    let num = domains.length;
    domain = domains[Math.floor(Math.random() * num)];
    // domain = domains[0];
  }
  return domain;
};

/**
 * 获取Check siteinfo的内容
 * @returns {Promise<string[] | null>}
 */
export const getSiteCheckInfo = async () => {
  const result = await getData(process.env.CHECK_LIST_CN, process.env.CHECK_LIST_GLOBAL)
    .then(x => {
      const siteInfo = AES_CBC_DECRYPT(x);
      return siteInfo.split(',').map(y => y.trim())
    })
    .catch(err => {
      console.log(err);
      return null;
    });
  return result;
};

/**
 * 获取AgencyOriented用的域名
 * @returns {Promise<string[] | null>}
 */
 export const getAgencyOrientedDomains = async () => {
  const result = await getData(process.env.AGENCY_ORIENTED_SITE_LIST_TXT_CN, process.env.AGENCY_ORIENTED_SITE_LIST_TXT_GLOBAL)
    .then(x => {
      const domainStr = AES_CBC_DECRYPT(x);
      let domains = domainStr.split(',').map((y) => y.trim());
      let domain = '';
      // console.log('AgencyOriented domains', domains)
      if(domains) {
        let num = domains.length;
        domain = domains[Math.floor(Math.random() * num)];
      }
      return `https://${domain}`;
    })
    .catch(err => {
      console.log(err);
      return null;
    });
  return result;
};

/**
 * 获取Affiliate用的域名
 * @returns {Promise<string[] | null>}
 */
 export const getAffiliateDomains = async () => {
  const result = await getData(process.env.AFFILIATE_SITE_LIST_TXT_CN, process.env.AFFILIATE_SITE_LIST_TXT_GLOBAL)
    .then(x => {
      const domainStr = AES_CBC_DECRYPT(x);
      let domains = domainStr.split(',').map((y) => y.trim());
      let domain = '';
      // console.log('AgencyOriented domains', domains)
      if(domains) {
        let num = domains.length;
        domain = domains[Math.floor(Math.random() * num)];
      }
      return `https://${domain}`;
    })
    .catch(err => {
      console.log(err);
      return null;
    });
  return result;
};

/**
 * 获取AUDIT用的日期與ratio data
 * @returns {Promise<string[] | null>}
 */
 export const getAuditData = async () => {
  const result = await getData(process.env.AUDIT_LIST_TXT_CN, process.env.AUDIT_LIST_TXT_GLOBAL)
    .then(x => {
      const data = AES_CBC_DECRYPT(x);
      return data;
    })
    .catch(err => {
      console.log(err);
      return null;
    });
    // console.log('SET_AUDIT_DATA:',result );
    if(isClient && window.$nuxt) window.$nuxt.$store.commit('SET_AUDIT_DATA', result);
  return result;
};
