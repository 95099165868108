import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from '~/plugins/firebase.js';
import { isClient } from '~/utils/envHelper';
// 修复zendesk节点
export const editZendeskDom = () => {
  let zdom = document && document.getElementById('zendesk-launcher-icon');
  if (zdom) {
    setTimeout(_ => {
      editZendeskDomAction2(null, zdom);
    });
  } else {
    let num = 0;
    let zendeskInterval = setInterval(_ => {
      editZendeskDomAction(zendeskInterval);
      num++;
      if (num >= 50) clearInterval(zendeskInterval);
    }, 200);
  }
}

export const editZendeskDomAction = (zendeskInterval) => {
  if (isClient) {
    let iframes = document.getElementsByTagName("iframe");
    for (let i = 0; i < iframes.length; i++) {
      if (iframes[i].title === "傳訊視窗") {
        zE('messenger', 'close');//reload頁面,关闭传讯视窗
      }else {
        // // TODO 此次暂时屏蔽，跨越时此段代码无法正常使用，且此方式不受官方支持
        // if (iframes[i] && iframes[i].contentWindow && iframes[i].contentWindow.document && iframes[i].contentWindow.document.body) {
        //   let btnBox = iframes[i].contentWindow.document.getElementsByTagName("button");
        //   if (btnBox && btnBox.length > 0 && btnBox[0].id !== 'closeButton') editZendeskDomAction2(zendeskInterval, iframes[i]);
        // }
      }
    }
  }
}

export const editZendeskDomAction2 = (zendeskInterval, iframeDom) => {
  if (isClient) {
  let params = window.$nuxt.$store.state.zendeskLauncherParams;
  let htmlBox = iframeDom.contentWindow.document.getElementsByTagName("html");
  let btnBox = iframeDom.contentWindow.document.getElementsByTagName("button");
  let nodeBox = btnBox[0].parentNode;
  let iconBox = btnBox[0].childNodes[0] || null;
  let iconHoverBox = btnBox[0].childNodes[1] || null;
  if (nodeBox) {
    iframeDom.id = 'zendesk-launcher-icon';
    if (params.width) iframeDom.style.width = params.width;
    if (params.height) iframeDom.style.height = params.height;
    if (params.position) {
      if (params.position.top) iframeDom.style.top = params.position.top;
      if (params.position.right) iframeDom.style.right = params.position.right;
      if (params.position.bottom) iframeDom.style.bottom = params.position.bottom;
      if (params.position.left) iframeDom.style.left = params.position.left;
    }
    nodeBox.setAttribute("style", "background-color: transparent !important;width: " + params.width + " !important;height: " + params.height + " !important;");
    if (iconBox) iconBox.innerHTML = `<img style="width: 100%;height: 100%;" src="${params.icon}" onmouseover="this.src='${params.hoverIcon || params.icon}'" onmouseout="this.src='${params.icon}'" />`;
    if (iconHoverBox) iconHoverBox.innerHTML = `<img style="width: 100%;height: 100%;" src="${params.hoverIcon}" />`;
    if (htmlBox && htmlBox.length > 0) htmlBox[0].style.background = "transparent";
    if (btnBox && btnBox.length > 0) {
      btnBox[0].style.background = "transparent";
      btnBox[0].addEventListener("click", serviceBtnClick);
    }
    if (zendeskInterval) clearInterval(zendeskInterval);
    setZEListening();
  }
  }
}

// 设置zE监听
export const setZEListening = () => {
  if (isClient) {
  let listeningState = window.$nuxt.$store.state.isListeningZendesk;
  if (listeningState === false) {
    // 监听Zendesk关闭
    if (zE) {
      zE('messenger:on', 'close', _ => {
        window.$nuxt.$store.commit('SET_IS_LISTENING_ZENDESK', true);
        editZendeskDom();
      });
    }
  }
  }
}

export const serviceBtnClick = () =>{
  logEvent(getAnalytics(firebaseApp), 'customServiceBtnClick_event', {
    platform: "web"
  });
}
