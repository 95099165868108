
import Cookies from 'js-cookie'
import {SUPPORTED_THEME} from "bgconfig";
import {getCookieDomain} from "utils";

export const LocalCookieKey = 'WEEX_LOCAL_COOKIE'

const CookieKey = {
    // 网站语言 默认 en
    LANG: "weex_lang",
    // 折合货币单位  默认CNY
    UNIT: "weex_unit",
    // 资产显示隐藏  默认 false
    SHOWASSET: "weeex_showasset",
    //资产折合估值单位
    VALUATIONUNIT: 'weex_valuationunit',
    // 交易区 主题 默认 dark
    THEME: "weex_theme",
    // 交易区布局
    LAYOUT: "weex_layout",
    //登录标识
    LOGIN: 'weex_login',
    // 主题色
    GLOBAL_THEME: 'global_theme',
    // 合约Pro主题色
    GLOBAL_THEME_NEW: 'global_theme_new'

}

const LocalCookie = {
    ready: false,
    /**
     * 保存的默认data
     */
    data: {
        [CookieKey.LANG]: 'en',
        [CookieKey.UNIT]: 'CNY',
        [CookieKey.SHOWASSET]: false,
        [CookieKey.THEME]: 'dark',
        [CookieKey.LAYOUT]: 'right',
        [CookieKey.VALUATIONUNIT]: 1,
        [CookieKey.LOGIN]: false,
        [CookieKey.GLOBAL_THEME]: SUPPORTED_THEME[0],
        [CookieKey.GLOBAL_THEME_NEW]: SUPPORTED_THEME[0],

    },
    /**
     * 从cookie中将缓存加载到本实例
     */
    init() {
        try {
            const localData = Cookies.get(LocalCookieKey);
            const local = localData ? JSON.parse(localData) : ''
            if (local) {
                // 防止数据缺失
                Object.assign(this.data, local)
            }
        } catch (e) {
            console.error('本地缓存数据被破坏，使用初始值', e)
        } finally {
            this.ready = true
        }
    },
    get(key: CookieKey) {
        if (!this.ready) {
            this.init()
        }
        return this.data[key]
    },
    set(key: CookieKey, value: any) {
        if (!key) {
            return;
        }
        if (!this.ready) {
            this.init()
        }
        if (this.data[key] === value) {
            return;
        }
        this.data[key] = value;
        try {
            const domain = getCookieDomain();
            Cookies.set(LocalCookieKey, JSON.stringify(this.data), { expires: 365, domain, secure: true, sameSite: 'Lax' })

        } catch (e) {
            console.error('Local cookie Error:', e)
        }
    },
}

export { CookieKey }
export default LocalCookie
