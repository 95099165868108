import {isClient, isLocal} from "./envHelper";
import {getFinger} from "./deviceInfo";
import moment from "moment";
import TokenCookie, {TokenKey} from "~/utils/TokenCookie";

/**
 * 发出神策事件
 * @param eventName     - 事件名称
 * @param eventvalue    - 事件value
 * @param ext           - 事件拓展数据
 * @returns {Promise<void>}
 */
const sEvent = async (eventName, eventvalue, ext = {}) => {
    const hostname = isClient && window.location.hostname;
    if (isClient && window.$nuxt) {
        const isDev = process.env.MODE === 'dev';
        // 如果ip访问或者localhost访问，则为本地开发，不上报信息；
        if(isLocal || isDev) return; // 本地測試可以先註解 方便神策上確認 (環境變量改project=production可在本地測試上報是否成功 SENSORS_RECEIVE_URL=https://app.sensor.weex.tech:8106/sa?project=production)
        let userId = window.$nuxt.$store.state.user.userId || '';
        let deviceId = localStorage.getItem("weex:terminalCode");
        if (!deviceId) {
            getFinger((finger) => {
                deviceId = finger
            })
        }
        const userToken = TokenCookie.get(TokenKey.R_TOKEN) || '';
        const localTime = moment().format('YYYY/MM/DD HH:mm:ss.SSS');
        const utcTime = moment().utc().format('YYYY/MM/DD HH:mm:ss.SSS');
        const language = window.$nuxt.$i18n.locale;
        window.$nuxt.$sensors.track(eventName, {
            userId,						    // 用户id
            deviceId,						// 装置id，从localStorage取，若无则前端自动生成并存入localStorage
            userToken,						// bt_rToken，从localStorage取
            localTime,	                // 客户端本地时间，yyyy/MM/dd HH:mm:ss.fff
            utcTime,	                    // 客户端时间对应UTC，yyyy/MM/dd HH:mm:ss.fff
            language,		                // i18n.language，或其他自定义的当前语系,en|zh-CN|zh-TW|jp|ko
            // event: eventName,
            value: eventvalue,
            ...ext
        });
    }
}

export default sEvent;

/**
 * 点击注册按钮
 **/
const ClickRegister = () => {
    sEvent('ClickRegister', '')
}

/**
 * 提交注册信息
 * @param register      - 注册方式 Mobile | Email
 * @param invite_code   - 邀请码 没有的话就传‘无’
 */
const SubRegister = ({register, invite_code = '无'}) => {
    sEvent('SubRegister', '', {register, invite_code})
}

/**
 * 获取验证码结果
 * @param Business_scene    - 业务场景 Login | Register
 * @param code_type         - 验证码类型 SMS ｜ Email
 * @param is_success        - 是否成功 Boolean
 * @param fail_reason       - 失败原因
 */
const CodeResult = ({Business_scene, code_type, is_success, fail_reason}) => {
    sEvent('CodeResult', '', {Business_scene, code_type, is_success, fail_reason})
}

/**
 * 注册结果
 * @param register      - 注册方式 Mobile | Email
 * @param invite_code   - 邀请码 没有的话就传‘无’
 * @param is_success    - 是否成功 Boolean
 * @param fail_reason   - 失败原因
 */
const RegisterResult = ({register, invite_code = '无', is_success, fail_reason}) => {
    sEvent('RegisterResult', '', {register, invite_code, is_success, fail_reason})
}

/**
 * 点击登录按钮
 */
const ClickSign = () => {
    sEvent('ClickSign', '')
}

/**
 * 登录结果
 * @param sign_type     - 登录方式 传填入的账号
 * @param is_success    - 是否成功 Boolean
 * @param fail_reason   - 失败原因
 */
const SignResult = ({sign_type, is_success, fail_reason}) => {
    sEvent('SignResult', '', {
        sign_type,
        is_success,
        fail_reason
    })
}

/**
 * 弹窗曝光
 * @param commonName    - 弹窗名称
 * @param popup_id      - 弹窗id
 */
const PopupExposure = ({commonName, popup_id}) => {
    sEvent('PopupExposure', '', {
        commonName,
        popup_id
    })
}

/**
 * 弹窗点击
 * @param commonName        - 弹窗点击
 * @param popup_id          - 弹窗id
 * @param click_action      - 点击动作 Close | GoTo
 */
const PopupClick = ({commonName, popup_id, click_action}) => {
    sEvent('PopupClick', '', {
        commonName,
        popup_id,
        click_action
    })
}

/**
 * 官网_首页按钮点击
 */
const PC_ButtonClick = () => {
    sEvent('PC_ButtonClick', '')
}

/**
 * 官网_banner点击
 * @param banner_name       - banner名称
 * @param banner_position   - banner位置
 */
const PC_BannerClick = (banner_name, banner_position) => {
    sEvent('PC_BannerClick', '', {banner_name, banner_position})
}

/**
 * 官网_主流交易对点击
 * @param tradepair_name    - 交易对名称
 */
const PC_TradePairClick = (tradepair_name) => {
    sEvent('PC_TradePairClick', '', {tradepair_name})
}

/**
 * 充值页面浏览
 * @param tradepair_name    - 充币币种
 */
const RechargeDetailView = (tradepair_name) => {
    sEvent('RechargeDetailView', '', {tradepair_name})
}

/**
 * 提币页面浏览
 * @param tradepair_name    - 提币币种
 * @constructor
 */
const ExtractDetailView = (tradepair_name) => {
    sEvent('ExtractDetailView', '', {tradepair_name})
}

/**
 * 提币完成
 * @param is_success        - 是否成功 Boolean
 * @param fail_reason       - 失败原因
 * @param link_name         - 链名称
 * @param tradepair_name    - 提币币种
 * @param tradeamount       - 提币数量
 */
const ExtractFinish = ({is_success, fail_reason, link_name, tradepair_name, tradeamount}) => {
    sEvent('ExtractFinish', '', {is_success, fail_reason, link_name, tradepair_name, tradeamount})
}

/**
 * 划转页面浏览
 */
const TransferDetailView = () => {
    sEvent('TransferDetailView', '')
}

/**
 * 划转完成
 * @param is_success            - 是否成功 Boolean
 * @param fail_reason           - 失败原因
 * @param original_account      - 原始账户
 * @param dest_account          - 转至账户
 * @param tradepair_name        - 提币币种
 * @param tradeamount           - 提币数量
 */
const TransferFinish = ({is_success, fail_reason, original_account, dest_account, tradepair_name, tradeamount}) => {
    sEvent('TransferFinish', '', {is_success, fail_reason,original_account,dest_account, tradepair_name, tradeamount,})
}

/**
 * 币币详情页面浏览
 * @param tradepair_name    - 交易对名称
 */
const BB_CoinDetailView = (tradepair_name) => {
    sEvent('BB_CoinDetailView', '', {tradepair_name})
}

/**
 * 币币买入买入完成
 * @param is_success        - 是否成功 Boolean
 * @param symbolId          - 交易对名称
 * @param orderType         - 币币交易方式  Limit | Market
 * @param delegatePrice     - 买入价
 * @param tradeAmount       - 买入量
 * @param fail_reason       - 失败原因
 */
const BB_BuyFinish = ({is_success, symbolId, orderType, delegatePrice, tradeAmount, fail_reason}) => {
    sEvent( 'BB_BuyFinish', '', {
        is_success,
        tradepair_name: symbolId,
        trademode: orderType === 0 ? 'Limit' : 'Market',
        tradeprice: delegatePrice,
        tradeamount: tradeAmount,
        fail_reason
    })
}

/**
 * 币币卖出完成
 * @param is_success        - 是否成功 Boolean
 * @param symbolId          - 交易对名称
 * @param orderType         - 币币交易方式  Limit | Market
 * @param delegatePrice     - 卖出价
 * @param tradeAmount       - 卖出量
 * @param fail_reason       - 失败原因
 */
const BB_SellFinish = ({is_success, symbolId, orderType, delegatePrice, tradeAmount, fail_reason}) => {
    sEvent('BB_SellFinish', '', {
        is_success,
        tradepair_name: symbolId,
        trademode: orderType === 0 ? 'Limit' : 'Market',
        tradeprice: delegatePrice,
        tradeamount: tradeAmount,
        fail_reason
    })
}

/**
 * 合约Pro详情页浏览
 * @param tradepair_name    -合约类型
 */
const CT_CoinDetailView = (tradepair_name) => {
    sEvent('CT_CoinDetailView', '', {tradepair_name})
}

/**
 * 合约Pro买入按钮点击事件
 * @param productCode       - 合约名称
 * @param delegateType      - 合约交易方式  Limit | Plan
 * @param tradePrice        - 下单策略  Maker | IOC | FOK
 * @param amount            - 买入价
 * @param triggerPrice      - 买入量
 * @param timeInForce       - 触发价 计划委托才传入
 */
const CT_SubBuyInfo = ({productCode,delegateType, tradePrice, amount, triggerPrice, timeInForce }) => {
    let ordertype = '';
    switch (timeInForce) {
        case 1:
            ordertype = 'Maker';
            break;
        case 3:
            ordertype = 'IOC';
            break;
        case 2:
            ordertype = 'FOK';
            break;
    }
    sEvent('CT_SubBuyInfo', '', {
        tradepair_name: productCode,  // 币对名称
        trademode: delegateType === 3 ? 'Plan' : 'Limit',  // 交易方式
        ordertype: delegateType == 1 ? ordertype : '',  // 限价订单类型
        tradeprice: tradePrice, // 买入价
        tradeamount: amount,  // 买入量
        trigger_price: delegateType === 3 ? triggerPrice : '',  // 计划委托触发价
    })
}

/**
 * 合约Pro卖出按钮点击事件
 * @param productCode       - 合约名称
 * @param delegateType      - 合约交易方式  Limit | Plan
 * @param tradePrice        - 下单策略  Maker | IOC | FOK
 * @param amount            - 卖出价
 * @param triggerPrice      - 卖出量
 * @param timeInForce       - 触发价 计划委托才传入
 */
const CT_SubSellInfo = ({productCode,delegateType, tradePrice, amount, triggerPrice, timeInForce }) => {
    let ordertype = '';
    switch (timeInForce) {
        case 1:
            ordertype = 'Maker';
            break;
        case 3:
            ordertype = 'IOC';
            break;
        case 2:
            ordertype = 'FOK';
            break;
    }
    sEvent('CT_SubSellInfo', '', {
        tradepair_name: productCode,
        trademode: delegateType === 3 ? 'Plan' : 'Limit',
        ordertype: delegateType == 1 ? ordertype : '',
        tradeprice: tradePrice,
        tradeamount: amount,
        trigger_price: delegateType === 3 ? triggerPrice : '',
    })
}

/**
 * 合约Pro买入完成
 * @param is_success    - 是否成功 Boolean
 * @param fail_reason   - 失败原因
 * @param productCode   - 合约名称
 * @param delegateType  - 合约交易方式  Limit | Plan
 * @param tradePrice    - 下单策略  Maker | IOC | FOK
 * @param amount        - 买入价
 * @param triggerPrice  - 买入量
 * @param timeInForce   - 触发价 计划委托才传入
 */
const CT_BuyFinish = ({is_success, fail_reason, productCode,delegateType, tradePrice, amount, triggerPrice, timeInForce }) => {
    let ordertype = '';
    switch (timeInForce) {
        case 1:
            ordertype = 'Maker';
            break;
        case 3:
            ordertype = 'IOC';
            break;
        case 2:
            ordertype = 'FOK';
            break;
    }
    sEvent('CT_BuyFinish', '', {
        is_success: is_success,
        fail_reason: fail_reason || '',
        tradepair_name: productCode,
        trademode: delegateType === 3 ? 'Plan' : 'Limit',
        ordertype: delegateType == 1 ? ordertype : '',
        tradeprice: tradePrice,
        tradeamount: amount,
        trigger_price: delegateType === 3 ? triggerPrice : '',
    })
}

/**
 * 合约Pro卖出完成
 * @param is_success    - 是否成功 Boolean
 * @param fail_reason   - 失败原因
 * @param productCode   - 合约名称
 * @param delegateType  - 合约交易方式  Limit | Plan
 * @param tradePrice    - 下单策略  Maker | IOC | FOK
 * @param amount        - 卖出价
 * @param triggerPrice  - 卖出量
 * @param timeInForce   - 触发价 计划委托才传入
 */
const CT_SellFinish = ({is_success, fail_reason, productCode,delegateType, tradePrice, amount, triggerPrice, timeInForce }) => {
    let ordertype = '';
    switch (timeInForce) {
        case 1:
            ordertype = 'Maker';
            break;
        case 3:
            ordertype = 'IOC';
            break;
        case 2:
            ordertype = 'FOK';
            break;
    }
    sEvent('CT_SellFinish', '', {
        is_success: is_success,
        fail_reason: fail_reason || '',
        tradepair_name: productCode,
        trademode: delegateType === 3 ? 'Plan' : 'Limit',
        ordertype: delegateType == 1 ? ordertype : '',
        tradeprice: tradePrice,
        tradeamount: amount,
        trigger_price: delegateType === 3 ? triggerPrice : '',
    })
}

/**
 * 上报错误
 * @param msg    - 错误信息
 **/
const ErrorLog = (msg) => {
  sEvent('ErrorLog', msg)
}

/**
 * 点击合约Pro列表
 **/
const ClickFutureList = () => {
    sEvent('Futures_FutureList', '')
}

/**
 * 点击合约Pro交易对
 * @param future_name   - 合约名称
 * @param future_tag    - 合约标签
 **/
const ClickFuturePair = (future_name, future_tag) => {
    sEvent('ClickFuturePair', '', {
        future_name: future_name,
        future_tag: future_tag
    })
}

/**
 * 点击首页热门币榜单
 **/
const ClickHomeRankList = () => {
    sEvent('Home_Rank', '')
}

/**
 * 点击首页合约交易对
 * @param future_name             - 合约名称
 * @param forward_entry_source    - 前向入口来源
 **/
const ClickHomeFuturePair = (future_name, forward_entry_source) => {
    sEvent('Home_FuturePair', '', {
        future_name: future_name,
        forward_entry_source: forward_entry_source
    })
}

/**
 * 浏览人人代理详情页面
 * @param platform_type        平台类型 web|APP
 * @param language             语言 中文|繁体|韩文|越南语|英文
 * @param forward_entry_source 页面来源:站内信|个人中心
 **/
const ViewLetterRenrenWebview = (platform_type, language,forward_entry_source) => {
    sEvent('Home_Letter_Renren_Webview', '', {
        platform_type,
        language,
        forward_entry_source
    })
   
}

/**
 * 点击分享人人代理详情页面
 * @param platform_type     平台类型 web|APP
 * @param language          语言  中文|繁体|韩文|越南语|英文
 * @param Share_type        分享方式 ：下载二维码|邀请码|邀请链接
 **/
const ClickProfileRenrenShare = (platform_type, language,Share_type) => {
    sEvent('Home_Profile_Renren_ClickShare', '', {
        platform_type,
        language,
        Share_type
    })
}

export {
    ViewLetterRenrenWebview,
    ClickProfileRenrenShare,
    ClickRegister,
    SubRegister,
    CodeResult,
    RegisterResult,
    ClickSign,
    SignResult,
    PopupExposure,
    PopupClick,
    PC_ButtonClick,
    PC_BannerClick,
    PC_TradePairClick,
    RechargeDetailView,
    ExtractDetailView,
    ExtractFinish,
    TransferDetailView,
    TransferFinish,
    BB_CoinDetailView,
    BB_BuyFinish,
    BB_SellFinish,
    CT_CoinDetailView,
    CT_SubBuyInfo,
    CT_SubSellInfo,
    CT_BuyFinish,
    CT_SellFinish,
    ErrorLog,
    ClickFutureList,
    ClickFuturePair,
    ClickHomeRankList,
    ClickHomeFuturePair
};
